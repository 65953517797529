<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="scan.title"></app-i18n>
          </h2>
          <section class="relative">
            <div>
              <div class="flex justify-center">
                <lottie-player
                  v-if="!verified & !badCode"
                  src="/code-scanner.json"
                  speed="1"
                  autoplay
                  :loop="true"
                  background="transparent"
                  style="width: 200px; height: 200px"
                ></lottie-player>
                <lottie-player
                  v-if="!verified && badCode"
                  src="/wrong.json"
                  speed="1"
                  autoplay
                  :loop="true"
                  background="transparent"
                  style="width: 200px; height: 200px"
                ></lottie-player>
                <lottie-player
                  v-if="verified && !badCode"
                  src="/success.json"
                  speed="1"
                  autoplay
                  :loop="true"
                  background="transparent"
                  style="width: 200px; height: 200px"
                ></lottie-player>
              </div>
              <div class="grid grid-cols-12 mt-9 gap-6">
                <h4
                  class="text-lg flex font-medium leading-none items-center justify-center mr-2 col-span-12"
                >
                  {{ i18n('scan.fields.qrCode') }}
                </h4>
                <div class="col-span-12 lg:mt-0 mt-2 flex justify-center">
                  <input
                    id="crud-form-1"
                    ref="codeRef"
                    type="text"
                    autofocus
                    class="form-control w-1/2 dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.code"
                    :placeholder="i18n('scan.placeholder.qrCode')"
                    required
                  />
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.verify') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <!-- <button
                  type="button"
                  class="
                    btn
                    bg-theme-32
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  @click="doCancel()"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button> -->
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default defineComponent({
  setup() {
    const errorMessage = ref('')
    const codeRef = ref('')
    const verified = ref(false)
    const badCode = ref(null)
    return {
      errorMessage,
      codeRef,
      badCode,
      verified
    }
  },
  data() {
    return {
      model: {
        code: ''
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.$refs.codeRef.focus())
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
      // record: 'admin/form/record',
      // findLoading: 'admin/form/findLoading',
      // saveLoading: 'admin/form/saveLoading'
    })
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.scan')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.scan')
    }
  },
  methods: {
    ...mapActions({
      doVerifyQRCode: 'log/list/doVerifyQRCode'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.code.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      return true
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const result = await this.doVerifyQRCode(this.model.code)

      if (result) {
        this.verified = true
      } else {
        this.verified = false
        this.badCode = true
      }
      setTimeout(() => {
        this.verified = false
        this.badCode = null
        this.model.code = ''
        this.$refs.codeRef.focus()
      }, 5000)
    }
  }
})
</script>
